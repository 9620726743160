module.exports = {
  linkResolver: doc => {
    // URL for a category type
    if (doc.type === "post") {
      return `/blog/${doc.uid}/`
    }

    return "/"
  },
}
