module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Comite Régional de Tir à l'Arc du Grand Est","short_name":"Tir à l'Arc Grand Est","start_url":"/","lang":"fr","background_color":"#fff","theme_color":"#0c3252","display":"standalone","icon":"src/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"eae9bda0e68f0ea498e980b0191cbc36"},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"tiralarc-grand-est","defaultLang":"fr-fr","path":"/preview/","previews":false,"pages":[{"type":"Post","match":"/blog/:uid/","previewPath":"/blog-preview/","component":"/builds/tiralarc-grand-est/front/src/templates/post.tsx","sortBy":"meta_lastPublicationDate_ASC"},{"type":"Welcome","match":"/bienvenue/","component":"/builds/tiralarc-grand-est/front/src/templates/welcome.tsx"}],"sharpKeys":[{},"profilepic"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://tracking.tiralarc-grand-est.fr","siteUrl":"https://tiralarc-grand-est.fr"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
